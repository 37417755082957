import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user: any;

  readonly token = 'token';


  constructor(
    private requestService: RequestService,
    private http: HttpClient) {
  }

  ngOnInit() {

  }


  getToken() {
    return localStorage.getItem(this.token);
  }

  isAuthenticated() {
    return this.getToken() != null;
  }

  logout() {
    localStorage.removeItem(this.token);
    this.user = null;
  }

  setPhoto(user_id: number, fichier: any) {
    return this.requestService.post('users/' + user_id + '/media64', { 'extension': 'jpg', 'content': fichier });
  }

  getPhoto(user_id: number): Observable<any> {
    return this.requestService.get('userPhoto/' + user_id);
  }

  updateProfile(id, nom, prenom): Observable<any> {
    return this.requestService.post('updateProfile', { id, nom, prenom });
  }

  listeObjets(client_id: number): Observable<any> {

    return this.http.get('http://apisquiddy.akensys.net/api/ak/listeObjets?client_id=' + client_id + "&query=", {});
  }
  notif(device_id, titre, message): Observable<any> {
    return this.http.post('http://apisquiddy.akensys.net/api/ak/notif', { device_id, titre, message });
  }










}
