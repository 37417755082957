import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import swal from "sweetalert2";
import { AuthService } from "src/app/core/services/auth.service";
import { FuncService } from "src/app/core/services/func.service";
import { MenuService } from "src/app/core/services/menu-service";
import { User } from "src/app/model/User";
import { UserService } from "src/app/core/services/user.service";

var misc: any = {
  sidebar_mini_active: true
}

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Send notifications",
    type: "link",
    icontype: "business_chart-pie-36"
  }
  /* ,
    {
      path: "/components",
      title: "Components",
      type: "sub",
      icontype: "education_atom",
      collapse: "components",
      isCollapsed: true,
      children: [
        { path: "buttons", title: "Buttons", ab: "B" },
        { path: "grid", title: "Grid System", ab: "GS" },
        { path: "panels", title: "Panels", ab: "P" },
        { path: "sweet-alert", title: "Sweet Alert", ab: "SA" },
        { path: "notifications", title: "Notifications", ab: "N" },
        { path: "icons", title: "Icons", ab: "I" },
        { path: "typography", title: "Typography", ab: "T" }
      ]
    },
    {
      path: "/forms",
      title: "Forms",
      type: "sub",
      icontype: "files_single-copy-04",
      collapse: "forms",
      isCollapsed: false,
      children: [
        { path: "regular", title: "Regular Forms", ab: "RF" },
        { path: "extended", title: "Extended Forms", ab: "EF" },
        { path: "validation", title: "Validation Forms", ab: "VF" },
        { path: "wizard", title: "Wizard", ab: "W" }
      ]
    },
    {
      path: "/tables",
      title: "Tables",
      type: "sub",
      icontype: "design_bullet-list-67",
      collapse: "tables",
      isCollapsed: true,
      children: [
        { path: "regular", title: "Regular Tables", ab: "RT" },
        { path: "extended", title: "Extended Tables", ab: "ET" },
        { path: "ngx-datatable", title: "Liste des matériels", ab: "ND" }
      ]
    },
    {
      path: "/maps",
      title: "Maps",
      type: "sub",
      icontype: "location_pin",
      collapse: "maps",
      isCollapsed: true,
      children: [
        { path: "google", title: "Google Maps", ab: "GM" },
        { path: "fullscreen", title: "Full Screen Map", ab: "FSM" },
        { path: "vector", title: "Vector Map", ab: "VM" }
      ]
    },
    {
      path: "/widgets",
      title: "Widgets",
      type: "link",
      icontype: "objects_diamond"
    },
    {
      path: "/charts",
      title: "Charts",
      type: "link",
      icontype: "business_chart-pie-36"
    },
    {
      path: "/calendar",
      title: "Calendar",
      type: "link",
      icontype: "media-1_album"
    } */
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  currentUser: User;
  logo: any;
  primary: any;
  userName: any;
  userPhoto: any;
  constructor(
    private funcService: FuncService,
    private menuService: MenuService,
    private authService: AuthService,
    private userService: UserService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.currentUser = this.funcService.JSONParse(localStorage.getItem('currentUser'));
    if (this.currentUser) {
      this.primary = this.currentUser.client.couleur_primary;
      this.userName = this.currentUser.prenom + " " + this.currentUser.nom
      this.userService.getPhoto(this.currentUser.id).subscribe(photo => this.userPhoto = photo);
      //this.loadLogo(this.currentUser.client.id)
    }
  }

  public loadLogo(clientId: number) {
    this.menuService.getLogo(this.currentUser.client.id).subscribe(img => {
      this.logo = img;
    })
  }
  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      var element = event.target;
      while (
        element.getAttribute("data-toggle") != "collapse" &&
        element != document.getElementsByTagName("html")[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (
        element.classList.contains("collapse") &&
        !element.classList.contains("show")
      ) {
        element.classList = "before-collapsing";
        var style = element.scrollHeight;

        element.classList = "collapsing";
        setTimeout(function () {
          element.setAttribute("style", "height:" + style + "px");
        }, 1);
        setTimeout(function () {
          element.classList = "collapse show";
          element.removeAttribute("style");
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute("style", "height:" + (style + 20) + "px");
        }, 3);
        setTimeout(function () {
          element.classList = "collapsing";
        }, 3);
        setTimeout(function () {
          element.removeAttribute("style");
        }, 20);
        setTimeout(function () {
          element.classList = "collapse";
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
      this.showSidebarMessage('Sidebar mini deactivated...');
    } else {
      body.classList.add('sidebar-mini');
      this.showSidebarMessage('Sidebar mini activated...');
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message) {
    this.toastr.show(
      '<span class="now-ui-icons ui-1_bell-53"></span>', message,
      {
        timeOut: 4000,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-danger alert-with-icon",
        positionClass: "toast-top-right"
      }
    );
  }

  deconnexion() {
    this.authService.logout();
  }

  getPrimary() {
    return this.primary;
  }

}
