import { Component } from "@angular/core";
import { User } from "./model/User";
import { FuncService } from "./core/services/func.service";
import { MenuService } from "./core/services/menu-service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "app";
  currentUser: User;

  constructor(
    private menuService: MenuService,
    private funcService: FuncService) { }

  ngOnInit() {
    this.currentUser = this.funcService.JSONParse(localStorage.getItem('currentUser'));

  }

}
