import { Injectable } from '@angular/core';
import { Objet } from '../model/Objet';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';

@Injectable()
export class ObjetService {

    constructor(
        private requestService: RequestService) {

    }

    creerObjet(id: number, client_id: number, categ_id: number, libelle: string, mac: string, serial: string): Observable<any> {
        return this.requestService.post('objet',
            {
                id,
                client_id,
                categ_id,
                libelle,
                mac,
                serial
            });
    }

    getObjetUpdateXY(client_id: number, mac: number, x: number, y: number): Observable<any> {
        return this.requestService.post('getObjetUpdateXY',
            {
                client_id,
                mac,
                x,
                y
            });
    }

    listeObjets(client_id: number, query: string): Observable<any> {
        return this.requestService.get<Objet[]>('listeObjets',
            {
                client_id,
                query
            });
    }

    envoyerFichier(objet_id: number, fichier: any) {
        return this.requestService.post('objets/' + objet_id + '/media64', { 'extension': 'jpg', 'content': fichier });
    }

    getPhoto(media_id: number): Observable<any> {
        return this.requestService.get('media/' + media_id);
        //        return this.requestService.get('media/' + media_id);
    }

    envoiTrace(message: String): Observable<any> {
        return this.requestService.post('objenious', { message });
    }

}
