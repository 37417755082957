import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
    private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.getToken()) {
/*       if (!this.auth.user) {
        const isAuthorized = this.auth.authUserWithToken();
        isAuthorized.subscribe(authorized => {
          if (!authorized) {
            this.router.navigate(['login']);
          }
        });
        return isAuthorized;
      }
 */      return true;
    }
    this.router.navigate(['login']);
    return false;
  }

}
