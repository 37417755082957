import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
//import { AppSettings } from './app-settings'
import { RequestService } from './request.service';

@Injectable()
export class MenuService {

  constructor(private requestService: RequestService) { }

  getId = (): string => 'menu';

  getTitle = (): string => 'titre1';


  saveMenu(role_id: number, listeMenuId: string): Observable<any> {
    return this.requestService.post('saveMenu', { 'role_id': role_id, 'listeMenuId': listeMenuId });
  }
  getLogo(client_id: number): Observable<any> {
    return this.requestService.get('clientLogo/' + client_id);
  }





  getDataForTheme = () => {
    return {
      "background": "assets/imgs/logo_menu.jpg",
      "image": "assets//imgs/24.jpg",
      "title": "",
      "description": "",
      "color": "1234"
    };
  };

  getEventsForTheme = (menuItem: any): any => {
    return {};
  };

  prepareParams = (item: any) => {
    return {
      title: item.title,
      data: {},
      events: this.getEventsForTheme(item)
    };
  };

/*   load(item: any): Observable<any> {
    var that = this;
     that.loadingService.show();
    return new Observable(observer => {
      that.loadingService.hide();
      observer.next(this.getDataForTheme());
      observer.complete();
    });
  }
 */}
