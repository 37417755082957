import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { User } from 'src/app/model/User';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  user: User;

  readonly TOKEN = 'token';

  constructor(private http: HttpClient, private router: Router) {

  }

  ngOnInit() {
    if (this.getToken()) {
      this.fetchUserSession();
    }
  }

  authenticate(login: string, password: string) {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.apiUrl}/api/login_connect`, {
        username: login,
        password
      }, { headers: { 'Content-Type': 'application/json' } })
        .subscribe((response: { token }) => {
          console.log(response.token);
          localStorage.setItem(this.TOKEN, response.token);
          this.fetchUserSession().subscribe(user => {
            this.user = user;
            resolve(user);
          });
        },
          err => {
            // Mauvais mot de passe
            reject(err);
          });
    });
  }

  public authUserWithToken() {
    return this.fetchUserSession().pipe(flatMap((user: User) => {
      this.user = user;
      console.log('connexion ok');
      return of(true);
    }));
  }

  /**
   * Fetch the user details from the server and store it in the service.
   * GetUser() to get the info
   */
  fetchUserSession(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/api/session`);
  }

  getUser(): User {
    return this.user;
  }

  getToken() {
    //return "ok"
    return localStorage.getItem(this.TOKEN);
  }

  isAuthenticated() {
    return this.user != null;
  }

  logout() {
    localStorage.removeItem(this.TOKEN);
    this.user = null;
    this.router.navigate(['login']);
  }
}
