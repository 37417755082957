import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RequestService } from './request.service';
import { switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class FuncService {

  constructor(
    private toastr: ToastrService,
    private service: RequestService) { }

  JSONParse(s) {
    if (s != "undefined") {
      return JSON.parse(s);
    }
    else {
      return null;

    }
  }

  toast(message, typeAlerte) {
    this.toastr.info(
      message,
      "",
      {
        timeOut: 2000,
        enableHtml: true,
        closeButton: true,
        toastClass: "alert alert-" + typeAlerte,
        positionClass: "toast-" + "top" + "-" + "center"
      }
    );

  }
}
