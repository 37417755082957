import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpParamsOptions } from '@angular/common/http/src/params';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl + '/api/';
  }

  get<T>(resourceUrl: string, params = {}): Observable<T> {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(key => httpParams = httpParams.append(key, params[key]));
    return this.http.get<T>(this.url + resourceUrl, { params: httpParams });
  }

  post<T>(resourceUrl: string, body, format = 'application/json'): Observable<T> {
    const options = {
      headers: {
        'content-type': format
      }
    };
    return this.http.post<T>(this.url + resourceUrl, body, options);
  }

  put<T>(resourceUrl: string, body: T, format = 'application/json'): Observable<T> {
    const options = {
      headers: {
        'content-type': format
      }
    };
    return this.http.put<T>(this.url + resourceUrl, body, options);
  }

  patch(resourceUrl: string, body) {
    return this.http.patch(this.url + resourceUrl, body);
  }

  delete(resourceUrl: string, id: number) {
    return this.http.delete(this.url + resourceUrl + '/' + id);
  }
}
