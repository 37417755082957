import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ComponentsModule } from "./components/components.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";

import { AppRoutes } from "./app.routing";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./core/interceptors/tokenInterceptor";
import { ObjetService } from "./core/services/objet.service";
import { MenuService } from "./core/services/menu-service";
import { FormBuilder } from "@angular/forms";
import { ObjetDetailComponent } from './components/objet-detail/objet-detail.component';
//import { NguiMapModule } from '@ngui/map';

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, ObjetDetailComponent],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    NgbModule,
    ToastrModule.forRoot(), // ToastrModule added
  /*   NguiMapModule.forRoot({
      apiUrl: "https://maps.google.com/maps/api/js?key=AIzaSyB0IprWVZKmsQLU704vcPLVPeqEtAyIh5Q"
    }),
   */  ComponentsModule
  ],
  providers: [
    ObjetService, MenuService, FormBuilder,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
